import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";

const Environment = true;

export const GlobalUrl =
  Environment === true
    ? "https://api.data-ignite.com:2053"
    : "https://api.data-ignite.com:2083"; // Default for development/preprod

export const apiBasePath =
  Environment === true ? "/auth/prod-tenant" : "/auth/test-tenant";

export const sasToken =
  Environment === true
    ? "sp=racwd&st=2024-10-25T11:11:02Z&se=2025-03-11T19:11:02Z&sv=2022-11-02&sr=c&sig=z1R6OYq2d2QT9NvFW%2FTjerZaUMmHoYSC7NdgWCfhmQ0%3D"
    : "sp=racwd&st=2024-10-25T11:12:00Z&se=2025-03-04T19:12:00Z&sv=2022-11-02&sr=c&sig=6gbewb4xuirBjmWXC1VpWh3f%2BRC2CyE4t1JH64P6u4I%3D";

export const containerName =
  Environment === true ? "prod-ignite-files" : "test-ignite-files";

export const drawerWidth = 260;

export const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

export const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  // backgroundColor: "#1A202C",
  // color: "#FFFFFF",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      // backgroundColor: "#1A202C",
      // color: "#FFFFFF",
      // boxShadow: "2px 0px 5px rgba(0, 0, 0, 0.3)"
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      backgroundColor: "#1A202C", // Apply the same background color when closed
      color: "#FFFFFF",
      boxShadow: "2px 0px 5px rgba(0, 0, 0, 0.3)",
    },
  }),
}));
