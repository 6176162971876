import React, { useState } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import Header from "../Header/Header";
import { sendPasswordResetEmail } from "supertokens-web-js/recipe/emailpassword";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(""); // For email-specific errors
  const [loading, setLoading] = useState(false);

  async function handleResetPassword() {
    setEmailError("");
    try {
      setLoading(true);
      let response = await sendPasswordResetEmail({
        formFields: [
          {
            id: "email",
            value: email,
          },
        ],
      });

      if (response.status === "FIELD_ERROR") {
        // one of the input formFields failed validation
        response.formFields.forEach((formField) => {
          if (formField.id === "email") {
            // Email validation failed (for example incorrect email syntax).
            setEmailError(formField.error);
            setLoading(false);
          }
        });
      } else if (response.status === "PASSWORD_RESET_NOT_ALLOWED") {
        setEmailError(response.status);
        setLoading(false);
        // this can happen due to automatic account linking. Please read our account linking docs
      } else {
        // reset password email sent.
        setLoading(false);
        window.alert("Please check your email for the password reset link");
      }
    } catch (err) {
      setLoading(false);
      if (err.isSuperTokensGeneralError === true) {
        // this may be a custom error message sent from the API by you.
        window.alert(err.message);
      } else {
        window.alert("Oops! Something went wrong.");
      }
    }
  }

  const handleBackToSignin = () => {
    // Logic for redirecting back to sign-in
    window.location.href = "/signin";
  };

  return (
    <>
      <Header /> {/* Render the header at the top */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="calc(100vh - 99px)"
        bgcolor="#EEEEEE"
        p={2}
      >
        <Box
          width={{ xs: "100%", sm: "400px" }} // Responsive width
          p={4} // Padding inside the form box
          borderRadius={4} // Rounded corners
          boxShadow={3} // Box shadow for depth effect
          bgcolor="white" // White background for the form
        >
          <form autoComplete="off">
            {" "}
            {/* Disable autocomplete for the entire form */}
            <Typography variant="h4" gutterBottom textAlign="center">
              Forgot Password
            </Typography>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="new-email" // Change autoComplete attribute
              error={Boolean(emailError)} // Show red border if error exists
              helperText={emailError} // Show email-specific error below the field
            />
            {loading ? (
              <CircularProgress />
            ) : (
              <Box
                mt={2}
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }} // Stack on small screens, row on larger screens
                gap={2} // Space between buttons
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleResetPassword}
                  fullWidth // Makes button full width on smaller screens
                >
                  Reset Password
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleBackToSignin}
                  fullWidth // Makes button full width on smaller screens
                >
                  Back to Sign In
                </Button>
              </Box>
            )}
          </form>
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;
