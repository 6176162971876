import React, { useState } from "react";
import { TextField, Button, Box, Typography, Link, IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Header from "../Header/Header";
import { signIn } from "supertokens-web-js/recipe/emailpassword";
import CircularProgress from "@mui/material/CircularProgress";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);

  async function signInClicked(e) {
    e.preventDefault();
    try {
      setLoading(true);
      setEmailError(false);
      setPasswordError(false);
      setError("");
      let response = await signIn({
        formFields: [
          { id: "email", value: email },
          { id: "password", value: password },
        ],
      });

      if (response.status === "WRONG_CREDENTIALS_ERROR") {
        setEmailError(true);
        setPasswordError(true);
        setError("Wrong credentials");
      } else if (response.status === "SIGN_UP_NOT_ALLOWED") {
        window.alert(response.reason);
      } else {
        window.location.href = "/";
      }
    } catch (err) {
      window.alert(err.isSuperTokensGeneralError ? err.message : "Oops! Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  const handleSignupRedirect = () => window.location.href = "/signup";
  const handleForgotPassword = () => window.location.href = "/forgotpassword";

  return (
    <>
      <Header />
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="calc(100vh - 99px)" bgcolor="#EEEEEE" p={2}>
        <Box width={{ xs: "100%", sm: "400px" }} p={4} borderRadius={4} boxShadow={3} bgcolor="white">
          <form onSubmit={signInClicked} autoComplete="off">
            <Typography variant="h4" gutterBottom textAlign="center">
              Sign In
            </Typography>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={emailError}
              helperText={emailError ? "Please check your email" : ""}
              autoComplete="new-email"
            />
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={passwordError}
              helperText={passwordError ? "Please check your password" : ""}
              autoComplete="new-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {error && (
              <Typography variant="body2" color="error" gutterBottom>
                {error}
              </Typography>
            )}
            {loading ? (
              <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            ) : (
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button variant="contained" color="primary" type="submit">
                  Sign In
                </Button>
                <Button variant="contained" color="secondary" onClick={handleSignupRedirect}>
                  Sign Up
                </Button>
              </Box>
            )}
            <Box mt={2} textAlign="center">
              <Link href="javascript:void(0);" onClick={handleForgotPassword}>
                Forgot Password?
              </Link>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default SignIn;
