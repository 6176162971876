import * as React from 'react'; 
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AppBars from '../AppBar/AppBar';
import Listitems from '../ListItems/ListItems';
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import axios from 'axios'; // Import axios for API calls
import Cookies from 'js-cookie'; // Import js-cookie to get token
import { GlobalUrl, DrawerHeader, Drawer } from "../Global";
import LinearProgress from "@mui/material/LinearProgress"; // LinearProgress for progress bar
import { useMediaQuery } from "@mui/material";

export default function TaskIdPage() {
  const theme = useTheme();
  const location = useLocation();
  
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(!isSmallScreen); // Closed by default on small screens
  const [taskLoading, setTaskLoading] = React.useState(false); // State for task status loading
  const [errorMessage, setErrorMessage] = React.useState("");
  const [taskStatus, setTaskStatus] = React.useState(null); // State to store task status
  const navigate = useNavigate();
  const Params = useParams();

  const token = Cookies.get('sFrontToken'); // Get token from cookies

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNavigation = (text) => {
    if (text === 'Private Collections') {
      navigate('/dataset/list/private');
    }
    if (text === 'Collections') {
      navigate('/dataset/list/public');
    }
    if (text === 'Upload New Dataset') {
      navigate('/upload');
    }
    if (text === "Notes") {
      navigate("/dataset/Notes");
    }
  };

  const fetchTaskStatus = async () => {
    if (Params.id) {
      setTaskLoading(true); // Start loading for task status
      try {
        const response = await axios.get(`${GlobalUrl}/doc/get-task-status/${Params.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setTaskStatus(response.data); // Save task status to state
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage("An unexpected error occurred.");
        }
      } finally {
        setTaskLoading(false); // Set loading to false when the API call finishes
      }
    }
  };

  React.useEffect(() => {
    fetchTaskStatus();
  }, []); // Fetch status when taskId changes

  
  React.useEffect(() => {
    setOpen(!isSmallScreen); // Update drawer state when screen size changes
  }, [isSmallScreen]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBars setOpen={setOpen} open={open} />

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Listitems handleNavigation={handleNavigation} open={open} />
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: '#EEEEEE', minHeight: "calc(100vh - 0px)", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <DrawerHeader />
        
        {/* Form Section */}
        <Box
            sx={{
              textAlign: "center",
              padding: 3,
              backgroundColor: "#f9f9f9",
              borderRadius: 2,
              boxShadow: 3,
            }}
          >
            <Typography variant="h6">Task Created Successfully</Typography>
            <Typography variant="body1">Your Task ID is: {Params.id}</Typography>
            {taskLoading ? (
              <LinearProgress sx={{ width: "100%", marginTop: 2 }} />
            ) : (
              <Box sx={{ marginTop: 2 }}>
                <Typography variant="h6">Task Status:</Typography>
                <Typography variant="body1">
                  {JSON.stringify(taskStatus?.task_status, null, 2)}
                </Typography>{" "}
                {/* Displaying the task status */}
                <button onClick={() => {fetchTaskStatus()}} style={{padding: '10px', marginTop: '10px', cursor: 'pointer'}}>Refresh</button>
              </Box>
            )}
          </Box>
        
      </Box>
    </Box>
  );
}
