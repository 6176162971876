import React, { useState } from "react";
import { TextField, Button, Box, Typography, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Header from "../Header/Header";
import { submitNewPassword } from "supertokens-web-js/recipe/emailpassword";
import CircularProgress from "@mui/material/CircularProgress";

const ConfirmPass = () => {
  const [newPass, setNewPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passError, setPassError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  async function handleResetPassword() {
    setPassError("");
    try {
      setLoading(true);
      let response = await submitNewPassword({
        formFields: [
          {
            id: "password",
            value: newPass,
          },
        ],
      });

      if (response.status === "FIELD_ERROR") {
        response.formFields.forEach((formField) => {
          if (formField.id === "password") {
            setPassError("formField.error");
            setLoading(false);
          }
        });
      } else if (response.status === "RESET_PASSWORD_INVALID_TOKEN_ERROR") {
        window.alert("Password reset failed. Please try again");
        setLoading(false);
        window.location.assign("/signin");
      } else {
        setLoading(false);
        window.alert("Password reset successful!");
        window.location.assign("/signin");
      }
    } catch (err) {
      setLoading(false);
      if (err.isSuperTokensGeneralError === true) {
        window.alert(err.message);
      } else {
        window.alert("Oops! Something went wrong.");
      }
    }
  }

  const handleBackToSignin = () => {
    window.location.href = "/signin";
  };

  return (
    <>
      <Header />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="calc(100vh - 99px)"
        bgcolor="#EEEEEE"
        p={2}
      >
        <Box
          width={{ xs: "100%", sm: "400px" }}
          p={4}
          borderRadius={4}
          boxShadow={3}
          bgcolor="white"
        >
          <form autoComplete="off">
            <Typography variant="h4" gutterBottom textAlign="center">
              Confirm Password
            </Typography>
            <TextField
              label="Confirm Password"
              variant="outlined"
              fullWidth
              margin="normal"
              type={showPassword ? "text" : "password"}
              value={newPass}
              onChange={(e) => setNewPass(e.target.value)}
              error={Boolean(passError)}
              helperText={passError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {loading ? (
              <CircularProgress />
            ) : (
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button variant="contained" color="primary" onClick={handleResetPassword}>
                  Reset Password
                </Button>
                <Button variant="contained" color="secondary" onClick={handleBackToSignin}>
                  Back to Sign In
                </Button>
              </Box>
            )}
          </form>
        </Box>
      </Box>
    </>
  );
};

export default ConfirmPass;
