import React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import LogoImage from "../Header/transparent-logo-white.png"; // Import the image
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircle from "@mui/icons-material/AccountCircle"; // Import the profile icon
import Session from "supertokens-web-js/recipe/session";

const drawerWidth = 240;

const AppBars = ({ open, setOpen }) => {
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    backgroundColor: "#FFBB74",
    color: "#ffffff", // White text color
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  async function handleLogout() {
    await Session.signOut();
    window.location.href = "/signin"; // or to wherever your logic page is
  }

  return (
    <AppBar position="fixed">
      <Toolbar
        sx={{
          overflow: "hidden",
          display: "flex",
          justifyContent: "space-between",
          width: "100vw", // Use 100vw to ensure it doesn't exceed viewport width
          padding: 0, // Reset padding to avoid overflow
          margin: 0, // Reset margin
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => {
            setOpen(!open);
          }}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={LogoImage}
            alt="Logo"
            style={{
              maxWidth: "150px", // Responsive logo size
              height: "auto",
              display: "block",
            }}
          />
        </Typography>
        <Typography>
          <h4
            style={{ fontSize: "15px", cursor: "pointer", margin: 0 }} // Reset margin to prevent overflow
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Ignite
          </h4>
        </Typography>
        {/* Profile Icon */}
        <IconButton sx={{ color: "white", marginLeft: "auto" }}>
          <AccountCircle />
        </IconButton>
        {/* Logout Button */}
        <IconButton sx={{ color: "white" }} onClick={handleLogout}>
          <LogoutIcon />
        </IconButton>
      </Toolbar>
      <style jsx>{`
        @media (max-width: 600px) {
          img {
            max-width: 120px; /* Reduce logo size for smaller screens */
          }
          h4 {
            font-size: 12px; /* Reduce font size for the title */
          }
        }
        /* Hide scrollbars (for testing only) */
        body::-webkit-scrollbar {
          display: none;
        }
        body {
          overflow: hidden; /* Hide scrollbar (testing only) */
        }
      `}</style>
    </AppBar>
  );
};

export default AppBars;
