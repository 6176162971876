import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import AppBars from "../AppBar/AppBar";
import Listitems from "../ListItems/ListItems";
import { useNavigate, useParams } from "react-router-dom";
import { GlobalUrl, DrawerHeader, Drawer } from "../Global";
import { useMediaQuery } from "@mui/material";
import Cookies from "js-cookie";
import axios from "axios";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CircularProgress } from "@mui/material"; // Import CircularProgress
import PushPinIcon from "@mui/icons-material/PushPin"; // Import unpin icon
import { grey, blue } from "@mui/material/colors"; // Import Material-UI colors

const ExpandableCard = () => {
  const [expanded, setExpanded] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [pinnedState, setPinnedState] = React.useState({});
  const navigate = useNavigate();
  const Params = useParams();
  const [loading, setLoading] = React.useState(true);

  const fetchData = async () => {
    try {
      const token = Cookies.get("sFrontToken");
      if (!token) {
        throw new Error("Access token is not available");
      }

      const response = await axios({
        method: "get",
        url: `${GlobalUrl}/doc/get-pinned-answer/${Params.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setData(response.data.data);

      const initialPinState = response.data.data.reduce((acc, item) => {
        acc[item.pinned_response_id] = true;
        return acc;
      }, {});
      setPinnedState(initialPinState);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleExpandClick = (i) => {
    setExpanded(expanded === i ? null : i);
  };

  const handleUnpinClick = async (item) => {
    try {
      const token = Cookies.get("sFrontToken");
      await axios({
        method: "delete",
        url: `${GlobalUrl}/doc/unpin-response/${item.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setPinnedState((prevState) => ({
        ...prevState,
        [item.id]: !prevState[item.id],
      }));
      fetchData();
    } catch (err) {
      console.log("Error unpinning the note:", err);
    }
  };

  return (
    <Grid container spacing={2}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography
            variant="h6"
            sx={{
              marginLeft: "20px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            DataSet Name : {Params.name}
          </Typography>
          {data?.map((item, i) => (
            <Grid item xs={12} sm={12} md={12} key={i}>
              <Card sx={{ maxWidth: "100%", marginBottom: 2 }}>
                <CardContent>
                  <Typography variant="h6">{item.question}</Typography>
                  <Collapse in={expanded === i} timeout="auto" unmountOnExit>
                    <Typography
                      variant="body1"
                      dangerouslySetInnerHTML={{
                        __html: item.answer,
                      }}
                      color="text.primary"
                      sx={{
        whiteSpace: "pre-wrap", // Ensures line breaks for long text
        wordBreak: "break-word", // Allows words to break to the next line
        overflowWrap: "break-word", // Adds line breaks within words if needed
        fontSize: { xs: "0.9rem", sm: "1rem" }, // Adjusts font size on smaller screens
      }}
                    />
                  </Collapse>
                </CardContent>
                <CardActions
                  disableSpacing
                  sx={{ justifyContent: "right", alignItems: "center" }}
                >
                  <Button onClick={() => handleExpandClick(i)}>
                    {expanded === i ? "Show Less" : "Show More"}
                  </Button>
                  <IconButton
                    onClick={() => handleExpandClick(i)}
                    aria-expanded={expanded === i}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleUnpinClick(item)}
                    aria-label="unpin note"
                    sx={{ marginLeft: 1 }}
                  >
                    <PushPinIcon
                      sx={{
                        color: pinnedState[item.id] ? blue[500] : grey[500],
                      }}
                    />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};

export default function NotesDetails() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(!isSmallScreen);
  const navigate = useNavigate();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNavigation = (text) => {
    if (text === "Private Collections") {
      navigate("/dataset/list/private");
    } else if (text === "Collections") {
      navigate("/dataset/list/public");
    } else if (text === "Upload New Dataset") {
      navigate("/upload");
    } else if (text === "Notes") {
      navigate("/dataset/Notes");
    }
  };

  React.useEffect(() => {
    setOpen(!isSmallScreen);
  }, [isSmallScreen]);

  return (
    <Box sx={{ display: "flex", height: "100vh", overflow: "hidden" }}>
      <CssBaseline />
      <AppBars setOpen={setOpen} open={open} />

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Listitems handleNavigation={handleNavigation} open={open} />
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "#EEEEEE",
          minHeight: "calc(100vh - 0px)",
          overflowY: "auto",
        }}
      >
        <DrawerHeader />
        <ExpandableCard />
      </Box>
    </Box>
  );
}
