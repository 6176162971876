import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";
import { useDropzone } from "react-dropzone";
import AppBars from "../AppBar/AppBar";
import Listitems from "../ListItems/ListItems";
import { useNavigate } from "react-router-dom";
import { BlobServiceClient } from "@azure/storage-blob"; // Azure Blob Storage SDK
import YourChildComponent from "./YourChildComponent";
import CircularProgress from "@mui/material/CircularProgress"; // Import CircularProgress
import { sasToken, containerName } from "../Global";
import { DrawerHeader, Drawer } from "../Global";

export default function FileUpload() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(!isSmallScreen); // Closed by default on small screens
  const [fileType, setFileType] = React.useState("");
  const [fileTypePath, setFileTypePath] = React.useState();
  const [file, setFile] = React.useState(null);
  const [error, setError] = React.useState(""); // Error state to show if the wrong file type is uploaded
  const navigate = useNavigate();
  const [showModel, setShowModel] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const storageAccountName = "dspkstorage"; // Your Azure storage account name

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNavigation = (text) => {
    if (text === "Private Collections") {
      navigate("/dataset/list/private");
    }
    if (text === "Collections") {
      navigate("/dataset/list/public");
    }
    if (text === "Upload New Dataset") {
      navigate("/upload");
    }
    if (text === "Notes") {
      navigate("/dataset/Notes");
    }
  };

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
    setFile(null); // Reset file when file type changes
    setError(""); // Reset error message on file type change
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      fileType === "csv" ? ".csv" : fileType === "pdf" ? "application/pdf" : "", // Allow only selected file type
    onDrop: (acceptedFiles) => {
      if (fileType === "csv") {
        const uploadedFile = acceptedFiles[0];
        if (uploadedFile.type !== "text/csv") {
          setError("Please upload a CSV file.");
          setFile(null);
        } else {
          setFile(uploadedFile);
          setError("");
        }
      } else if (fileType === "pdf") {
        const invalidFiles = acceptedFiles.filter(
          (file) => file.type !== "application/pdf"
        );
        if (invalidFiles.length > 0) {
          setError("Please upload only PDF files.");
          setFile(null);
        } else {
          setFile(acceptedFiles); // For PDFs, store all selected files
          setError("");
        }
      }
    },
    multiple: fileType === "pdf", // Allow multiple files only for PDF
  });

  // Function to handle file upload to Azure
  // Handle file upload to Azure
  const handleUpload = async () => {
    if (!file || (Array.isArray(file) && file.length === 0)) {
      alert("Please select a file first!");
      return;
    }

    try {
      setLoading(true);
      // Create BlobServiceClient with the SAS token
      const blobServiceClient = new BlobServiceClient(
        `https://dspkstorage.blob.core.windows.net/?${sasToken}`
      );

      // Get the container client
      const containerClient =
        blobServiceClient.getContainerClient(containerName);

      if (Array.isArray(file)) {
        // Array to hold URLs of all uploaded files
        const fileUrls = [];

        // Handle multiple files (for PDFs)
        for (let i = 0; i < file.length; i++) {
          const currentFile = file[i];
          const blockBlobClient = containerClient.getBlockBlobClient(
            currentFile.name
          );
          const uploadBlobResponse = await blockBlobClient.uploadBrowserData(
            currentFile
          );
          // console.log(
          //   `Upload successful: ${uploadBlobResponse.requestId} for file: ${currentFile.name}`
          // );

          // Construct the file URL and add to fileUrls array
          const fileUrl = blockBlobClient.url.split("?")[0]; // Only take the base URL without query parameters
          fileUrls.push(fileUrl);
        }

        // Join the URLs for display or further use
        setFileTypePath(fileUrls);
        // console.log("All file URLs:", fileUrls);

        setLoading(false);
        setShowModel(true);
      } else {
        // Handle single file (for CSV or single PDF)
        const blockBlobClient = containerClient.getBlockBlobClient(file.name);
        const uploadBlobResponse = await blockBlobClient.uploadBrowserData(
          file
        );
        // console.log(`Upload successful: ${uploadBlobResponse.requestId}`);

        // Construct the file URL
        const fileUrl = blockBlobClient.url.split("?")[0]; // Only take the base URL without query parameters
        setShowModel(true);
        setFileTypePath(fileUrl);

        setLoading(false);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      alert("File upload failed. Please try again.");
      setLoading(false);
    }
  };

  React.useEffect(() => {
    setOpen(!isSmallScreen); // Update drawer state when screen size changes
  }, [isSmallScreen]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBars setOpen={setOpen} open={open} />

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Listitems handleNavigation={handleNavigation} open={open} />
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3, md: 4 }, // Responsive padding
          backgroundColor: "#EEEEEE",
          minHeight: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" }, // Adjust based on header height
        }}
      >
        <DrawerHeader />

        {showModel === false ? (
          <>
            {/* File Type Selection */}
            <Box sx={{ mb: 3 }}>
              <Select
                value={fileType}
                onChange={handleFileTypeChange}
                displayEmpty
                fullWidth
              >
                <MenuItem value="" disabled>
                  Select file type
                </MenuItem>
                <MenuItem value="csv">CSV</MenuItem>
                <MenuItem value="pdf">PDF</MenuItem>
              </Select>
            </Box>

            {/* Drag and Drop or File Selection */}
            <Box
              {...getRootProps()}
              sx={{
                border: "2px dashed #ccc",
                padding: { xs: 3, sm: 4, md: 6 }, // Responsive padding for box
                textAlign: "center",
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
                cursor: "pointer",
                minHeight: { xs: "150px", sm: "200px" }, // Responsive minHeight
              }}
            >
              <input {...getInputProps()} />
              {file && Array.isArray(file) ? (
                <Typography variant="subtitle1">
                  Selected files: {file.map((f) => f.name).join(", ")}
                </Typography>
              ) : file ? (
                <Typography variant="subtitle1">
                  Selected file: {file.name}
                </Typography>
              ) : (
                <Typography variant="subtitle1">
                  Drag and drop a file here, or click to select one
                </Typography>
              )}
            </Box>

            {/* Display error message if invalid file type is uploaded */}
            {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}

            {/* Upload Button */}
            {loading ? (
              <CircularProgress />
            ) : (
              <Box sx={{ mt: { xs: 1, sm: 2 } }}>
                {" "}
                {/* Responsive margin */}
                <Button
                  variant="contained"
                  disabled={!fileType || !file || error}
                  onClick={handleUpload}
                >
                  Upload File
                </Button>
              </Box>
            )}
          </>
        ) : (
          <YourChildComponent
            setShowModel={setShowModel}
            file={file}
            fileTypePath={fileTypePath}
          />
        )}
      </Box>
    </Box>
  );
}
