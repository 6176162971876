import React, { useState } from "react";
import { TextField, Button, Box, Typography, Link, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Header from "../Header/Header";
import { signUp } from "supertokens-web-js/recipe/emailpassword";
import CircularProgress from "@mui/material/CircularProgress";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  async function signUpClicked(e) {
    e.preventDefault();

    if (password !== confirmPassword) {
      setGeneralError("Passwords don't match");
      return;
    }
    try {
      setEmailError("");
      setPasswordError("");
      setGeneralError("");
      setLoading(true);
      let response = await signUp({
        formFields: [
          { id: "email", value: email },
          { id: "password", value: password },
        ],
      });

      if (response.status === "FIELD_ERROR") {
        response.formFields.forEach((formField) => {
          if (formField.id === "email") {
            setEmailError(formField.error);
            setLoading(false);
          } else if (formField.id === "password") {
            setPasswordError(formField.error);
            setLoading(false);
          }
        });
      } else if (response.status === "SIGN_UP_NOT_ALLOWED") {
        window.alert(response.reason);
        setLoading(false);
      } else {
        setLoading(false);
        window.location.href = "/";
      }
    } catch (err) {
      setLoading(false);
      if (err.isSuperTokensGeneralError === true) {
        window.alert(err.message);
      } else {
        window.alert("Oops! Something went wrong.");
      }
    }
  }

  const handleSignin = () => {
    window.location.href = "/signin";
  };

  const handleForgotPassword = () => {
    window.location.href = "/forgotpassword";
  };

  return (
    <>
      <Header />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="calc(100vh - 99px)"
        bgcolor="#EEEEEE"
        p={2}
      >
        <Box
          width={{ xs: "100%", sm: "400px" }}
          p={4}
          borderRadius={4}
          boxShadow={3}
          bgcolor="white"
        >
          <form autoComplete="off" onSubmit={signUpClicked}>
            <Typography variant="h4" gutterBottom textAlign="center">
              Sign Up
            </Typography>
            {generalError && (
              <Typography variant="body2" color="error" gutterBottom>
                {generalError}
              </Typography>
            )}
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              autoComplete="new-email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={Boolean(emailError)}
              helperText={emailError}
            />
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              autoComplete="new-password"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={Boolean(passwordError)}
              helperText={passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleToggleConfirmPasswordVisibility} edge="end">
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {loading ? (
              <Box mt={2} display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </Box>
            ) : (
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button variant="contained" color="primary" onClick={handleSignin}>
                  Sign In
                </Button>
                <Button variant="contained" color="secondary" type="submit">
                  Sign Up
                </Button>
              </Box>
            )}
            <Box mt={2} textAlign="center">
              <Link href="javascript:void(0);" onClick={handleForgotPassword}>
                Forgot Password?
              </Link>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default SignUp;
