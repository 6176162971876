import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { useMediaQuery } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import AppBars from '../AppBar/AppBar';
import Listitems from '../ListItems/ListItems';
import { DrawerHeader, Drawer } from "../Global";

export default function HomeScreen() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(!isSmallScreen); // Closed by default on small screens
  const navigate = useNavigate(); // Initialize navigate

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNavigation = (text) => {
    if (text === 'Collections') {
      navigate('/dataset/list/public'); // Navigate to the specific URL when "Collections" is clicked
    }
    if (text === 'Private Collections') {
      navigate('/dataset/list/private'); // Navigate to the specific URL when "Collections" is clicked
    }
    if (text === 'Upload New Dataset') {
      navigate('/upload');
    }
    if (text === "Notes") {
      navigate("/dataset/Notes");
    }
  };

  React.useEffect(() => {
    setOpen(!isSmallScreen); // Update drawer state when screen size changes
  }, [isSmallScreen]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline /> {/* Apply global styles */}
      {/* AppBar remains visible always */}
      <AppBars setOpen={setOpen} open={open} />

      {/* Drawer remains visible always */}
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Listitems handleNavigation={handleNavigation} open={open} />
      </Drawer>

      {/* Conditional rendering of main content based on the path */}
      <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: '#EEEEEE', minHeight: "calc(100vh - 0px)" }}>
        <DrawerHeader /> {/* Push content down under the AppBar */}

        {/* Show main content depending on the current pathname */}
          <>
            <Typography paragraph>
              <h1>Welcome to DataSpeak</h1>
            </Typography>
            <Typography paragraph>
              <p>This is an AI software, start exploring it.</p>
            </Typography>
          </>
      </Box>
    </Box>
  );
}
