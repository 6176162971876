import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SignIn from './component/SigninPage/Signin';
import SignUp from './component/SignUp/SignUp';
import ForgotPassword from './component/ForgotPassword/ForgotPassword';
import HomeScreen from './component/HomeScreen/HomeScreen';
import DataSet from './component/DataSet/DataSet';
import PrivateSet from './component/PrivateSet/PrivateSet';
import FileUpload from './component/FileUpload/FileUpload';
import DetailsPageRoute from './component/DetailsPageRoute/DetailsPageRoute';
import ConfirmPass from './component/ConfirmPass/ConfirmPass';
import Notes from './component/Notes/Notes';
import { GlobalUrl, apiBasePath } from './component/Global';
import NotesDetails from './component/Notes/Notesdetails';
import TaskIdPage from './component/FileUpload/taskIdPage';
import PrivateRoute from './component/PrivateRoute'; // Import PrivateRoute
import SuperTokens from 'supertokens-web-js';
import Session from 'supertokens-web-js/recipe/session';
import EmailPassword from 'supertokens-web-js/recipe/emailpassword';

SuperTokens.init({
  appInfo: {
    apiDomain: GlobalUrl,
    apiBasePath: apiBasePath,
    appName: "Ignite",
  },
  recipeList: [
    Session.init(),
    EmailPassword.init(),
  ],
});

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/account/reset-password" element={<ConfirmPass />} />
        <Route path="/react" element={<Navigate to="/" />} />

        {/* Protect routes with PrivateRoute */}
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/dataset/list/public" element={<DataSet />} />
          <Route path="/dataset/notes" element={<Notes />} />
          <Route path="/dataset/list/private" element={<PrivateSet />} />
          <Route path="/upload" element={<FileUpload />} />
          <Route path="/collection/:dataset_name/:type" element={<DetailsPageRoute />} />
          <Route path="/dataset/notes/:id/:name" element={<NotesDetails />} />
          <Route path="/dataset/upload/:id" element={<TaskIdPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
