import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import PushPinIcon from "@mui/icons-material/PushPin";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "js-cookie";
import axios from "axios";
import AppBars from "../AppBar/AppBar";
import Listitems from "../ListItems/ListItems";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { GlobalUrl, DrawerHeader, Drawer } from "../Global";
import { useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const CustomSelect = styled(Select)({
  "& .MuiSelect-select": {
    minHeight: "0.1em !important", // Force smaller height with !important
    height: "12px !important",
    paddingBottom: "20px",
  },
});

export default function DetailsPageRoute() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(!isSmallScreen); // Closed by default on small screens
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [prompt, setPrompt] = React.useState("");
  const [modelName, setModelName] = React.useState("llama3_70b_chat");
  const [resultSource, setResultSource] = React.useState("1");
  const [searchLogic, setSearchLogic] = React.useState("1");
  const [searchTerm, setSearchTerm] = React.useState(""); // New state for search term
  const navigate = useNavigate();
  const Params = useParams();
  const DocParams = Params.type === "CSV_QA" ? "spark3-csv" : "spark3-doc";
  const decodedDatasetName = decodeURIComponent(Params.dataset_name);
  const [newloading, setNewLoading] = React.useState(false);
  const [boolensCheck, setBoolean] = React.useState(false);
  const [suggestedQuestions, setSuggestedQuestions] = React.useState([]); // State for suggestions
  const [searchHistory, setSearchHistory] = React.useState([]); // Store search terms and their responses
  const [drawerOpen, setDrawerOpen] = React.useState(true); // State for Drawer
  const [promptLoading, setPromptLoading] = React.useState(false);
  const [promptLoadingUpdate, setPromptLoadingUpdate] = React.useState(false);
  const [datasetId, setDataSetId] = React.useState();
  // State to track button color and pin status for each response
  const [likedResponses, setLikedResponses] = React.useState({});
  const [dislikedResponses, setDislikedResponses] = React.useState({});
  const [pinnedResponses, setPinnedResponses] = React.useState({});

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get("sFrontToken");
        if (!token) {
          throw new Error("Access token is not available");
        }

        const response = await axios({
          method: "get",
          url: `${GlobalUrl}/doc/core-dataset-info?dataset_name=${decodedDatasetName}&index_name=${DocParams}`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        setData(response.data.data);
        setPrompt(response.data.data.system_prompt);
        setDataSetId(response.data.data.dataset_id);
        setSuggestedQuestions(response.data.data.suggested_questions);
      } catch (err) {
        setError(err.message || "Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [Params, DocParams]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleNavigation = (text) => {
    if (text === "Private Collections") {
      navigate("/dataset/list/private");
    }
    if (text === "Collections") {
      navigate("/dataset/list/public");
    }
    if (text === "Upload New Dataset") {
      navigate("/upload");
    }
    if (text === "Notes") {
      navigate("/dataset/Notes");
    }
  };

  const handleUpdatePrompt = async () => {
    const token = Cookies.get("sFrontToken");
    if (!token) {
      alert("Access token is not available");
      return;
    }

    const body = {
      dataset_name: `${decodedDatasetName}`,
      index_name: DocParams,
      system_prompt: prompt,
    };

    try {
      setPromptLoadingUpdate(true);
      setPromptLoading(true);
      const response = await axios.post(
        `${GlobalUrl}/doc/update-prompt`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setPromptLoading(false);
    } catch (err) {
      console.error("Error updating prompt:", err);
      setPromptLoading(false);
    }
  };

  React.useEffect(() => {
    if (promptLoadingUpdate) {
      setTimeout(() => {
        setPromptLoadingUpdate(false);
      }, "8000");
    }
  }, [promptLoadingUpdate]);

  const apiCallWithCount = async () => {
    const token = Cookies.get("sFrontToken");
    try {
      const response = await axios.get(
        `${GlobalUrl}/doc/user-query?model_name=${modelName}&index_name=${DocParams}&dataset_name=${decodedDatasetName}&local_search=${resultSource}&logic=${searchLogic}&count=5&system_prompt=${prompt}&search_query=${searchTerm}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  let apiCallCount = 0; // Declare outside the function to persist across calls
  const fetchApiData = async (currentSearchTerm) => {
    setNewLoading(true);
    let allData = [];
    let allSuggestedQuestions = [];

    // Increment the global counter to keep track of total API calls
    for (let i = 1; i <= 1; i++) {
      const result = await apiCallWithCount();
      allData.push(result); // Collect the result

      // Update the search history with the current term and append the new result
      setSearchHistory((prev) => {
        const lastEntry = prev[prev.length - 1]; // Get the last search entry
        if (lastEntry && lastEntry.term === currentSearchTerm) {
          // If it's the same term, append the response
          return [
            ...prev.slice(0, -1), // Keep all previous entries
            { ...lastEntry, responses: [...lastEntry.responses, result] }, // Update last entry
          ];
        } else {
          // If it's a new term, add a new entry
          return [...prev, { term: currentSearchTerm, responses: [result] }];
        }
      });

      // Extract and update suggestions every 5th call (5, 10, 15, etc.)
      if (result?.suggested_questions) {
        allSuggestedQuestions = result.suggested_questions || [];
        setSuggestedQuestions(allSuggestedQuestions); // Update UI with new suggestions
      }
    }

    setNewLoading(false);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    setSearchHistory((prev) => [...prev, { term: searchTerm, responses: [] }]); // Initialize new search entry
    fetchApiData(searchTerm); // Call the API to fetch data
    setSearchTerm(""); // Clear the input field
  };

  // Handlers for button actions
  const handleLikeClick = (questionId) => {
    setLikedResponses((prev) => ({
      ...prev,
      [questionId]: !prev[questionId], // Toggle liked status
    }));
    updateQuestionAction(questionId, "like");
  };

  const handleDislikeClick = (questionId) => {
    setDislikedResponses((prev) => ({
      ...prev,
      [questionId]: !prev[questionId], // Toggle disliked status
    }));
    updateQuestionAction(questionId, "dis_like");
  };

  const handlePinClick = (questionId, answer) => {
    setPinnedResponses((prev) => ({
      ...prev,
      [questionId]: !prev[questionId], // Toggle pinned status
    }));
    updateQuestionAction(questionId, "pin", answer);
  };

  // API call function
  const updateQuestionAction = async (questionId, action, answer = null) => {
    try {
      const token = Cookies.get("sFrontToken");
      await axios.post(
        `${GlobalUrl}/doc/update-question/${questionId}`,
        { action, answer },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("API error:", error);
    }
  };

  // const handlePinClick = async (
  //   question,
  //   answer,
  //   entryIndex,
  //   responseIndex
  // ) => {
  //   const isPinned =
  //     pinnedResponses[`${entryIndex}-${responseIndex}`]?.isPinned;
  //   const pinnedResponseId =
  //     pinnedResponses[`${entryIndex}-${responseIndex}`]?.pinned_response_id;

  //   const token = Cookies.get("sFrontToken");
  //   const body = {
  //     dataset_id: `${datasetId}`,
  //     question: question,
  //     response: answer,
  //   };

  //   try {
  //     let response;
  //     if (isPinned && pinnedResponseId) {
  //       // Unpin the response (DELETE request)
  //       response = await axios.delete(
  //         `${GlobalUrl}/doc/unpin-response/${pinnedResponseId}`, // Use the pinned_response_id for deletion
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Content-Type": "application/json",
  //             Accept: "application/json",
  //           },
  //         }
  //       );

  //       // Update the state to reflect the unpinning
  //       setPinnedResponses((prevState) => ({
  //         ...prevState,
  //         [`${entryIndex}-${responseIndex}`]: {
  //           isPinned: false, // Set to false after unpinning
  //           pinned_response_id: null, // Clear the pinned_response_id
  //         },
  //       }));
  //     } else {
  //       // Pin the response (POST request)
  //       response = await axios.post(`${GlobalUrl}/doc/pin-response`, body, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //           Accept: "application/json",
  //         },
  //       });

  //       const { pinned_response_id } = response.data; // Assuming the response contains pinned_response_id

  //       setPinnedResponses((prevState) => ({
  //         ...prevState,
  //         [`${entryIndex}-${responseIndex}`]: {
  //           isPinned: true,
  //           pinned_response_id: pinned_response_id, // Store the pinned_response_id
  //         },
  //       }));
  //     }
  //   } catch (err) {
  //     console.error("Error updating pin/unpin:", err);
  //   }
  // };

  const handleSuggestionClick = async (suggestion) => {
    setSearchTerm(suggestion); // Set the search term for the API call
    setBoolean(!boolensCheck);
    // Fetch API data based on the clicked suggestion immediately
    // await fetchApiData(suggestion);
  };

  React.useEffect(() => {
    if (searchTerm) {
      fetchApiData(searchTerm);
      setSearchTerm(""); // Clear the input field
    }
  }, [boolensCheck]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  React.useEffect(() => {
    setOpen(!isSmallScreen); // Update drawer state when screen size changes
  }, [isSmallScreen]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBars setOpen={setOpen} open={open} />

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Listitems handleNavigation={handleNavigation} open={open} />
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "#F3F3F3",
          minHeight: "100vh",
          // justifyContent: 'center',
        }}
      >
        <DrawerHeader />
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            {error && <Typography color="error">Error: {error}</Typography>}
            {data && (
              <Card
                variant="outlined"
                sx={{
                  margin: { xs: 1, sm: 2 }, // Margin for small screens
                  padding: { xs: 1, sm: 2 }, // Padding for small screens
                  maxHeight: { xs: "90vh", sm: "auto" }, // Limit height for small screens
                  overflowY: "auto", // Enable scrolling on overflow
                }}
              >
                <CardContent>
                  <IconButton onClick={toggleDrawer}>
                    <MenuIcon />
                  </IconButton>
                  {drawerOpen == true && (
                    <Card
                      variant="outlined"
                      sx={{
                        padding: { xs: 1, sm: 2 },
                        margin: { xs: 1, sm: 2 },
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant="h5"
                            component="div"
                            sx={{
                              fontSize: { xs: "1.2rem", sm: "1.5rem" }, // Responsive font size
                            }}
                          >
                            Dataset Name: {decodedDatasetName || "N/A"}
                          </Typography>
                          <Typography
                            sx={{ fontSize: { xs: 12, sm: 14 } }}
                            color="text.secondary"
                            gutterBottom
                          >
                            Record Count: {data.record_count || "N/A"}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: { xs: "0.9rem", sm: "1rem" },
                            }}
                          >
                            Type: {Params.type || "N/A"}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            variant="h6"
                            sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
                          >
                            Prompt
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <TextField
                              variant="outlined"
                              size="small"
                              value={prompt}
                              onChange={(e) => setPrompt(e.target.value)}
                              placeholder="Enter your prompt"
                              multiline
                              rows={2}
                              maxRows={2}
                              sx={{
                                flexGrow: 1,
                                marginRight: 1,
                                width: { xs: "100%", sm: "auto" }, // Full width on small screens
                              }}
                            />
                            {promptLoading ? (
                              <Box sx={{ marginLeft: 1 }}>
                                <CircularProgress />
                              </Box>
                            ) : (
                              <Button
                                variant="contained"
                                onClick={handleUpdatePrompt}
                                disabled={promptLoadingUpdate}
                                sx={{
                                  color: promptLoadingUpdate
                                    ? "rgba(18, 17, 17, 0.94)"
                                    : "white",
                                  fontSize: { xs: "0.8rem", sm: "1rem" },
                                }}
                              >
                                {promptLoadingUpdate ? "Updated" : "Update"}
                              </Button>
                            )}
                          </Box>
                        </Grid>

                        <Grid
                          container
                          spacing={{ xs: 1, sm: 2 }}
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" }, // Stack items vertically on small screens
                            flexWrap: "nowrap",
                          }}
                        >
                          <Grid item sx={{ flexGrow: 1, width: "100%" }}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                              <InputLabel>Model Name</InputLabel>
                              <CustomSelect
                                value={modelName}
                                onChange={(e) => setModelName(e.target.value)}
                                label="Model Name"
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      maxHeight: 100,
                                      overflowY: "auto",
                                    },
                                  },
                                }}
                              >
                                <MenuItem value="llama3_70b_chat">
                                  llama3_70b_chat
                                </MenuItem>
                              </CustomSelect>
                            </FormControl>
                          </Grid>

                          <Grid item sx={{ flexGrow: 1, width: "100%" }}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                              <InputLabel>Result From</InputLabel>
                              <CustomSelect
                                value={resultSource}
                                onChange={(e) =>
                                  setResultSource(e.target.value)
                                }
                                label="Result From"
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      maxHeight: 100,
                                      overflowY: "auto",
                                    },
                                  },
                                }}
                              >
                                <MenuItem value="1">Local</MenuItem>
                                <MenuItem value="2">Local & Global</MenuItem>
                              </CustomSelect>
                            </FormControl>
                          </Grid>

                          <Grid item sx={{ flexGrow: 1, width: "100%" }}>
                            <FormControl fullWidth sx={{ mb: 2 }}>
                              <InputLabel>Search Logic</InputLabel>
                              <CustomSelect
                                value={searchLogic}
                                onChange={(e) => setSearchLogic(e.target.value)}
                                label="Search Logic"
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      maxHeight: 100,
                                      overflowY: "auto",
                                    },
                                  },
                                }}
                              >
                                <MenuItem value="1">Logic1</MenuItem>
                                <MenuItem value="2">Logic2</MenuItem>
                              </CustomSelect>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {searchHistory?.map((entry, entryIndex) => (
                        <div key={entryIndex} style={{ width: "100%" }}>
                          <Typography
                            sx={{
                              marginTop: { xs: "20px", sm: "36px" },
                              marginBottom: { xs: "16px", sm: "24px" },
                              marginLeft: { xs: "8px", sm: "10px" },
                            }}
                            variant="h6"
                          >
                            {entry.term}
                          </Typography>
                          {entry?.responses?.map((response, responseIndex) => (
                            <Card
                              key={responseIndex}
                              variant="outlined"
                              sx={{
                                margin: { xs: "8px auto", sm: "10px auto" },
                                padding: 2,
                                borderRadius: "15px",
                                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                width: "100%", // Ensures card takes up full width of container
                                maxWidth: { xs: "95vw", sm: "80vw" }, // Prevents card from going off screen
                                overflowWrap: "break-word", // Ensures long words wrap
                              }}
                            >
                              <CardContent>
                                <Typography
                                  variant="body2"
                                  component="div"
                                  sx={{
                                    wordWrap: "break-word", // Ensures text breaks at container edges
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: response?.data,
                                  }}
                                />
                              </CardContent>
                              <CardActions
                                sx={{
                                  justifyContent: "flex-end",
                                  padding: "8px",
                                }}
                              >
                                <Box sx={{ display: "flex", gap: "8px" }}>
                                  <IconButton
                                    size="small"
                                    color={
                                      likedResponses[response.question_id]
                                        ? "primary"
                                        : "default"
                                    }
                                    onClick={() =>
                                      handleLikeClick(response.question_id)
                                    }
                                  >
                                    <ThumbUpIcon />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    color={
                                      dislikedResponses[response.question_id]
                                        ? "primary"
                                        : "default"
                                    }
                                    onClick={() =>
                                      handleDislikeClick(response.question_id)
                                    }
                                  >
                                    <ThumbDownIcon />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    color={
                                      pinnedResponses[response.question_id]
                                        ? "primary"
                                        : "default"
                                    }
                                    onClick={() =>
                                      handlePinClick(
                                        response.question_id,
                                        response.data
                                      )
                                    }
                                  >
                                    <PushPinIcon />
                                  </IconButton>
                                </Box>
                              </CardActions>
                            </Card>
                          ))}
                        </div>
                      ))}

                      {newloading ? (
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <CircularProgress />
                          </Box>
                        </Grid>
                      ) : (
                        <>
                          <Grid item xs={12} sx={{ padding: { xs: 1, sm: 2 } }}>
                            <Typography variant="h6">Suggestions</Typography>
                            <ul>
                              {suggestedQuestions.length > 0 ? (
                                suggestedQuestions.map((suggestion, index) => (
                                  <li key={index}>
                                    <Typography
                                      onClick={() =>
                                        handleSuggestionClick(suggestion)
                                      }
                                      sx={{
                                        cursor: "pointer",
                                        color: "blue",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      {suggestion}
                                    </Typography>
                                  </li>
                                ))
                              ) : (
                                <Typography>
                                  No suggestions available
                                </Typography>
                              )}
                            </ul>
                          </Grid>
                          <Grid item xs={12} sx={{ padding: { xs: 1, sm: 2 } }}>
                            <Typography variant="h6">Search</Typography>
                            <Box
                              component="form"
                              onSubmit={handleSearch}
                              sx={{
                                display: "flex",
                                flexDirection: { xs: "column", sm: "row" },
                                gap: 1,
                              }}
                            >
                              <input
                                type="text"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  borderRadius: "8px",
                                  border: "1px solid #ccc",
                                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                  marginBottom: { xs: "10px", sm: "0" },
                                }}
                              />
                              <Button variant="contained" type="submit">
                                Search
                              </Button>
                            </Box>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
