import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  useMediaQuery,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import LogoImage from "./transparent-logo-white.png"; // Import the image

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar
        sx={{
          backgroundColor: "#FFBB74",
          color: "#ffffff",
        }}
      >
        {/* Box wrapping the logo and MyApp text */}
        <Box display="flex" alignItems="center" style={{ flexGrow: 1 }}>
          <img
            src={LogoImage}
            alt="Logo"
            style={{ maxWidth: 210, marginRight: 8 }}
          />{" "}
          {/* Image */}
          <Typography variant="h6">Ignite1</Typography>
        </Box>

        {/* Desktop View: Buttons in the toolbar */}
        {/* {!isMobile ? (
          <>
            button will be added here when needed
            <Button color="inherit">Home</Button>
            <Button color="inherit">About</Button>
            <Button color="inherit">Contact</Button>
          </>
        ) : (
          <>
            <IconButton edge="start" color="inherit" onClick={handleMenuOpen}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose}>Home</MenuItem>
              <MenuItem onClick={handleMenuClose}>About</MenuItem>
              <MenuItem onClick={handleMenuClose}>Contact</MenuItem>
            </Menu>
          </>
        )} */}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
