import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

const drawerWidth = 240;

const Listitems = ({handleNavigation, open}) => {

  return (
    <List>
    {['Collections', 'Private Collections', 'Notes' , 'Upload New Dataset'].map((text, index) => (
      <ListItem
        key={text}
        disablePadding
        sx={{ display: 'block' }}
        onClick={() => handleNavigation(text)} // Add onClick to handle navigation
      >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: 2.5,
            '&:hover': {
              backgroundColor: '#1976d2', // Background color on hover
              color: '#fff', // Text color on hover
              '& .MuiListItemIcon-root': {
                color: '#fff', // Icon color on hover
              },
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : 'auto',
              justifyContent: 'center',
              color: 'inherit', // Inherit color for hover effect
            }}
          >
            {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
          </ListItemIcon>
          <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </ListItem>
    ))}
  </List>
  );
};

export default Listitems;
