// PrivateRoute.js
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Session from 'supertokens-web-js/recipe/session';

function PrivateRoute() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  React.useEffect(() => {
    async function checkSession() {
      const sessionExists = await Session.doesSessionExist();
      setIsAuthenticated(sessionExists);
      setIsLoading(false);
    }
    checkSession();
  }, []);

  if (isLoading) return null; // or add a loading spinner if needed

  return isAuthenticated ? <Outlet /> : <Navigate to="/signin" />;
}

export default PrivateRoute;
